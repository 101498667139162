<template>
  <v-flex lg4 md6 sm12>
    <v-card class="rounded overflow-hidden ma-2 bg-default">
      <v-card-text class="pl-1 py-2 pr-0"
        :class="{
          'v-card__hidden' : isHidden
        }">
        <v-list three-line
          :class="{
            'v-list__hidden' : isHidden
          }">
          <v-list-tile avatar class="d-flex ma-0">
            <v-layout column xs12>
              <div class="d-flex justify-center align-center">
                <v-flex xs2 justify-center>
                  <v-list-tile-avatar class="mt-0 mx-auto justify-center" style="min-width:78px;min-height:78px;height:78px;width:78px;">
                    <div
                      class="d-flex align-center relative"
                      style="width:78px;height:78px"
                    >
                      <v-icon v-if="device.communication_type == 'WiFi'" class="ma-0" size="64" color="#0d920d">wifi</v-icon>
                      <v-icon v-else class="ma-0" size="64" color="#f09000">settings_input_antenna</v-icon>
                    </div>
                  </v-list-tile-avatar>
                </v-flex>
                <v-flex xs10>
                  <v-list-tile-content class="network-management__details ">
                    <div class="ellipsis-text">
                      Device ID:
                      <span class="black--text">{{ device.identifier }}</span>
                    </div>
                    <div class="ellipsis-text">
                      Description:
                      <span class="black--text">{{ device.description }}</span>
                    </div>
                    <div class="ellipsis-text">
                      Type:
                      <span class="black--text">{{ device.communication_type }}</span>
                    </div>
                  </v-list-tile-content>
                </v-flex>
              </div>
              <div class="mb-2">
                <v-layout row wrap class="session-details outlet-buttons">
                  <v-flex text-xs-left>
                    <button
                      @click="
                        show_test_connection_dialog = true;
                        testConnection();
                      "
                      class="outlet-button mt-1 mr-2"
                      :class="{ 'disabled__device-tile' : !ableTo.TEST_CONNECTION }"
                      :disabled="!ableTo.TEST_CONNECTION"
                    >
                      <span class="outlet-button-title" :class="{ 'disabled__device-tile-text' : !ableTo.TEST_CONNECTION }">Test Connection</span>
                    </button>
                    <button
                      @click="rebootDevice()"
                      class="outlet-button mt-1 mr-2"
                      :class="{ 'disabled__device-tile' : !ableTo.TEST_CONNECTION }"
                      :disabled="!ableTo.REBOOT_DEVICE"
                    >
                      <span class="outlet-button-title" :class="{ 'disabled__device-tile-text' : !ableTo.REBOOT_DEVICE }">Reboot</span>
                    </button>
                    <button v-if="false" @click="show_connect_by_bluetooth_dialog = true" class="outlet-button mt-1">
                      <span class="outlet-button-title">Connect By Bluetooth</span>
                    </button>
                  </v-flex>
                  <v-flex v-if="device.communication_type == 'WiFi'" text-xs-right>
                    <button 
                      @click="expanded = !expanded" 
                      class="outlet-button outlet-button-secondary outlet-arrow mt-1 ml-2" 
                      :disabled="!ableTo.GET_CONNECTED_NETWORK"
                      :class="{ 'disabled__device-tile' : !ableTo.GET_CONNECTED_NETWORK }"
                    >
                      <v-progress-circular indeterminate color="orange" :size="18" small v-if="busy" />
                      <template v-else>
                        <v-icon size="18" style="margin: 0 0 -3px 0" v-if="expanded">keyboard_arrow_up</v-icon>
                        <v-icon size="18" style="margin: 0 0 -3px 0" v-else>keyboard_arrow_down</v-icon>
                      </template>
                    </button>
                  </v-flex>
                </v-layout>
              </div>
            </v-layout>
          </v-list-tile>
        </v-list>
      </v-card-text>
      <div v-show="expanded" transition="slide-y-transition" class="outlet-controls">
        <v-flex class="text-xs-center pb-4" v-if="busy">
          <v-progress-circular class="loading-circle mt-4" size="28" indeterminate color="orange" />
        </v-flex>
        <div v-else-if="is_device_offline" class="text-xs-center pa-3">
          <span class="users-title">Device is offline</span>
        </div>
        <template v-else>
          <v-layout xs12 class="my-2">
            <v-flex text-xs-center>
              <button @click="show_add_wifi_dialog = true" class="outlet-button mt-1 mr-2">
                <span class="outlet-button-title">Add Network</span>
              </button>
              <button @click="show_clear_wifi_dialog = true" class="outlet-button mt-1">
                <span class="outlet-button-title">Clear Networks</span>
              </button>
            </v-flex>
          </v-layout>
          <v-layout class="my-4 px-4" xs12 column>
            <v-flex xs12 class="d-flex py-2 bordered-top bordered-bottom">
              <h4>Connected Network</h4>
              <v-icon v-if="!loadings.connected_network" @click="getConnectedNetwork()" class="pointer text-xs-right">refresh</v-icon>
              <v-flex class="text-xs-right" v-else>
                <v-progress-circular class="loading-circle" size="20" indeterminate color="orange" />
              </v-flex>
            </v-flex>
            <v-flex class="text-xs-center pb-4" v-if="loadings.connected_network">
              <v-progress-circular class="loading-circle mt-4" size="28" indeterminate color="orange" />
            </v-flex>
            <v-flex v-else-if="datas.connected_network" xs12 class="mt-2">
              <p class="my-1">
                SSID: <span class="font-weight-medium">{{ datas.connected_network.ssid }}</span>
              </p>
              <p class="my-1" v-if="datas.connected_network.bssid">
                BSSID: <span class="font-weight-medium">{{ datas.connected_network.bssid }}</span>
              </p>
              <p class="my-1" v-if="datas.connected_network.strength">
                Strength: <span class="font-weight-medium">{{ datas.connected_network.strength }}%</span>
              </p>
              <p class="my-1" v-if="datas.connected_network.quality">
                Quality: <span class="font-weight-medium">{{ datas.connected_network.quality }}%</span>
              </p>
              <p class="my-1" v-if="datas.connected_network.localIP">
                IP: <span class="font-weight-medium">{{ datas.connected_network.localIP }}</span>
              </p>
              <p class="my-1" v-if="datas.connected_network.subnetMask">
                Subnet Mask: <span class="font-weight-medium">{{ datas.connected_network.subnetMask }}</span>
              </p>
              <p class="my-1" v-if="datas.connected_network.gatewayIP">
                Gateway IP: <span class="font-weight-medium">{{ datas.connected_network.gatewayIP }}</span>
              </p>
            </v-flex>
            <v-flex v-else xs12 class="mt-2">
              <p class="my-1">
                No connected network found.
              </p>
            </v-flex>
          </v-layout>
          <v-layout class="my-4 px-4" xs12 column>
            <v-flex xs12 class="d-flex py-2 bordered-top bordered-bottom">
              <h4 class="d-block align-center" style="flex-grow:0!important">
                Stored Networks
              </h4>
              <v-icon
                class="tooltip-icon ml-2"
                v-tooltip="{
                  content: 'You can store up to 10 networks. The device will automatically search and connect to the first stored network detected.',
                  trigger: 'click hover',
                }"
                style="flex-grow:0!important"
              >
                info
              </v-icon>
              <v-icon v-if="!loadings.stored_networks" @click="getStoredNetworks()" class="pointer text-xs-right">refresh</v-icon>
              <v-flex class="text-xs-right" v-else>
                <v-progress-circular class="loading-circle" size="20" indeterminate color="orange" />
              </v-flex>
            </v-flex>
            <v-flex class="text-xs-center pb-4" v-if="loadings.stored_networks">
              <v-progress-circular class="loading-circle mt-4" size="28" indeterminate color="orange" />
            </v-flex>
            <template v-else-if="datas.stored_networks.length > 0">
              <v-flex v-for="(stored_network, i) in datas.stored_networks" :key="i" xs12 class="mt-2">
                <p class="my-1" v-if="stored_network && stored_network.ssid">
                  SSID: <span class="font-weight-medium">{{ stored_network ? stored_network.ssid : null }}</span>
                </p>
                <p class="my-1" v-if="stored_network && stored_network.security">
                  Security Type: <span class="font-weight-medium">{{ stored_network ? stored_network.security : null }}</span>
                </p>
              </v-flex>
            </template>
            <v-flex v-else xs12 class="mt-2">
              <p class="my-1">
                No stored network found.
              </p>
            </v-flex>
          </v-layout>
        </template>
      </div>
    </v-card>

     <v-dialog v-if="false" v-model="show_connect_by_bluetooth_dialog" max-width="350">
      <v-card>
        <v-card-title>
          <h3>Connect By Bluetooth</h3>
        </v-card-title>
        <v-card-text>
          <p>Please make sure the device is powered on and you are within close proximity to the device.</p>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="show_connect_by_bluetooth_dialog = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              flat="flat"
              @click.stop.prevent="$router.push({ path: '/owner/ble-configuration', query: { identifier: device.identifier, autoStart: true } })"
              >Continue</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-dialog v-model="show_test_connection_dialog" max-width="350">
      <v-card>
        <v-card-title>
          <h3>Test Connection</h3>
        </v-card-title>
        <v-card-text>
          <v-flex class="flex flex-column justify-center align-center text-xs-center" v-if="busy">
            <v-progress-circular class="loading-circle mt-4" size="40" indeterminate color="black" />
            <div class="my-4">
              <h2>Checking if device is online...</h2>
            </div>
          </v-flex>
          <p v-else class="text-xs-center">Device {{ device.identifier }} is {{ is_device_offline ? "offline" : "online" }}</p>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-spacer></v-spacer>
            <v-btn v-if="busy" color="gray" flat="flat" @click="show_test_connection_dialog = false">Cancel</v-btn>
            <v-btn v-else color="green darken-1" flat="flat" @click="show_test_connection_dialog = false">
              OK
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RebootDevice :show="show_reboot_dialog" :busy="busy" :error="error" :device="device" @close="show_reboot_dialog = false" />
    <v-dialog v-model="show_add_wifi_dialog" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Add Network</h3>
        </v-card-title>
        <v-card-text v-if="show_add_wifi_dialog">
          <v-flex v-if="add_network.loading" class="flex flex-column justify-center align-center text-xs-center">
            <v-progress-circular class="loading-circle mt-4" size="40" indeterminate color="black" />
            <div class="my-4">
              <h2>Adding new network...</h2>
            </div>
          </v-flex>
          <v-layout v-else-if="!add_network.submitted" xs12>
            <v-flex>
              <AddNetworkForm
                @cancelled="show_add_wifi_dialog = false"
                @submitted="addNetwork"
                cancel-button-class="v-btn--outline v-btn--depressed v-btn--round v-btn--small theme--light"
                confirm-button-class="v-btn--outline v-btn--depressed v-btn--round v-btn--small theme--light green--text"
              />
            </v-flex>
          </v-layout>
          <template v-else>
            <v-layout xs12>
              <v-flex xs12 v-if="add_network.success">
                <v-layout xs12 justify-center>
                  <v-flex xs12 class="text-xs-center">
                    <div class="text-xs-center">
                      <h3 class="success--text">WiFi Network Added</h3>
                    </div>

                    <v-icon class="text-xs-center my-4 mx-0" size="64" color="#0d920d">check_circle</v-icon>

                    <p class="text-xs-center">Plugzio device will now automatically attempt to connect to the network</p>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex v-else>
                <v-layout xs12 justify-center>
                  <v-flex xs12 class="text-xs-center">
                    <div class="text-xs-center">
                      <h3 class="danger--text">Adding WiFi Network Failed</h3>
                    </div>

                    <v-icon class="text-xs-center my-4 mx-0" size="64" color="#FF5252">close</v-icon>

                    <p class="text-xs-justify">
                      Please make sure the Plugzio device is powered on and connected to the internet. 
                      You can connect the device to the internet by hot spotting a network on your phone with the follow WiFi credentials:
                    </p>

                    <p class="text-xs-justify ma-4">
                      Default credentials are:<br />
                      <span class="ml-4 mt-2">Name: <strong>Plugzio</strong></span> <br />
                      <span class="ml-4">Password: <strong>WelcomePlugzio</strong></span>
                    </p>
                    <p class="text-xs-left">
                      Contact Plugzio <span class="font-weight-medium cursor-pointer success--text" @click="$store.dispatch('showFeedback', true)">Support</span> if you are still
                      having issues.
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout xs12 class="mt-4">
              <v-flex xs12 style="display:flex">
                <v-btn v-if="!add_network.success" @click="add_network.submitted = false" color="gray" flat="flat">Retry</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat="flat" @click="show_add_wifi_dialog = false">
                  OK
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_clear_wifi_dialog" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Clear WiFi Networks</h3>
        </v-card-title>
        <v-card-text>
          <v-flex v-if="clear_wifi.loading" class="flex flex-column justify-center align-center text-xs-center">
            <v-progress-circular class="loading-circle mt-4" size="40" indeterminate color="black" />
            <div class="my-4">
              <h2>Clearing networks...</h2>
            </div>
          </v-flex>
          <template v-else-if="!clear_wifi.submitted">
            <v-layout xs12>
              <v-flex>
                <v-layout xs12 justify-center>
                  <v-flex xs12 class="text-xs-center">
                    <div class="text-xs-center">
                      <h3 class="error--text">Warning</h3>
                    </div>

                    <p class="text-xs-justify mt-4">
                      This will permanently delete and remove all stored WiFi networks on the device. Once the networks are deleted, the device will no longer attempt to connect to
                      the deleted networks on the next reboot or power cycle. This action cannot be undone and networks will have to be manually added back to the device.
                    </p>
                    <p class="text-xs-left">List of stored networks:</p>
                    <div class="text-xs-left">
                      <ul>
                        <li v-for="(wifi, index) in datas.stored_networks" :key="index">
                          <span v-if="wifi">
                            <strong>{{ wifi.ssid }}</strong>
                            <span class="ml-2 grey--text">
                              ({{ wifi.security === "Unsecured" ? "Unsecured" : "Secured" }})
                              <span v-if="wifi.ssid === 'Plugzio'">[Default]</span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p class="text-xs-left mt-4">
                      Are you sure to proceed?
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout xs12 class="mt-4">
              <v-flex xs12 style="display:flex">
                <v-btn @click="show_clear_wifi_dialog = false" color="gray" flat="flat">Back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat="flat" @click="clearWifi()">
                  Confirm
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <template v-else>
            <v-layout xs12>
              <v-flex xs12 v-if="clear_wifi.success">
                <v-layout xs12 justify-center>
                  <v-flex xs12 class="text-xs-center">
                    <div class="text-xs-center">
                      <h3 class="success--text">WiFi Networks Cleared</h3>
                    </div>

                    <v-icon class="text-xs-center my-4 mx-0" size="64" color="#0d920d">check_circle</v-icon>

                    <!-- <p class="text-xs-center">Plugzio device will now automatically attempt to connect to the network</p> -->
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex v-else>
                <v-layout xs12 justify-center>
                  <v-flex xs12 class="text-xs-center">
                    <div class="text-xs-center">
                      <h3 class="danger--text">Clearing WiFi Networks Failed</h3>
                    </div>

                    <v-icon class="text-xs-center my-4 mx-0" size="64" color="#FF5252">close</v-icon>

                    <p class="text-xs-justify">
                      Please make sure the Plugzio device is powered on and connected to the internet. 
                      You can connect the device to the internet by hot spotting a network on your phone with the follow WiFi credentials:
                    </p>

                    <p class="text-xs-justify ma-4">
                      Default credentials are:<br />
                      <span class="ml-4 mt-2">Name: <strong>Plugzio</strong></span> <br />
                      <span class="ml-4">Password: <strong>WelcomePlugzio</strong></span>
                    </p>
                    <p class="text-xs-left">
                      Contact Plugzio <span class="font-weight-medium cursor-pointer success--text" @click="$store.dispatch('showFeedback', true)">Support</span> if you are still
                      having issues.
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout xs12 class="mt-4">
              <v-flex xs12 style="display:flex">
                <v-btn v-if="clear_wifi.success == false" @click="clearWifi()" color="gray" flat="flat">Retry</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat="flat" @click="show_clear_wifi_dialog = false">
                  OK
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapState } from "vuex";
import variables from "@/mixins/variables";
import Api from "@library/apis/Api";
import AddNetworkForm from "@/components/forms/AddNetworkForm"
import RebootDevice from "@/components/modals/RebootDevice.vue";
import { OwnerProxyHelpers } from "@/library/helpers";

export default {
  props: {  
    device: {
      type: Object,
      required: true,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [variables],
  components: { AddNetworkForm, RebootDevice },
  data() {
    return {
      add_network: {
        submitted: false,
        loading: false,
        success: false,
      },
      busy: false,
      error: false, 
      clear_wifi: {
        submitted: false,
        loading: false,
        success: false,
      },
      commands: {
        WIFI_ADD_NETWORK: 0,
        WIFI_GET_STORED_NETWORKS: 1,
        WIFI_GET_CONNECTED_NETWORK: 2,
        WIFI_CLEAR_STORED_NETWORKS: 3,
        WIFI_REBOOT: 4,
        WIFI_SCAN: 5,
        DEVICE_VERIFY_CONNECTION: 6,
        DEVICE_REBOOT: 7
      },
      datas: {
        connected_network: null,
        stored_networks: [],
      },
      is_device_offline: false,
      expanded: false,
      loadings: {
        connected_network: false,
        stored_networks: false,
      },
      show_add_wifi_dialog: false,
      show_clear_wifi_dialog: false,
      show_connect_by_bluetooth_dialog: false,
      show_test_connection_dialog: false,
      show_reboot_dialog: false
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ableTo() {
      return { 
        TEST_CONNECTION: OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_NETWORK_TEST_CONNECTION"),
        REBOOT_DEVICE: OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_NETWORK_REBOOT"), 
        GET_CONNECTED_NETWORK: OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_NETWORK_INFO") 
      }
    }
  },
  watch: {
    expanded: function(value) {
      if (value) {
        this.testConnection().then(() => {
          if (!this.is_device_offline) {
            this.busy = true;
            this.getConnectedNetwork().then(() => this.getStoredNetworks().then(() => (this.busy = false)));
          }
        });
      }
    },
    show_add_wifi_dialog: function(value) {
      this.$nextTick(() => {
        this.add_network.submitted = false;
        this.add_network.loading = false;
        this.add_network.success = false;
      });
    },
    show_clear_wifi_dialog: function(value) {
      this.$nextTick(() => {
        this.clear_wifi.submitted = false;
        this.clear_wifi.loading = false;
        this.clear_wifi.success = false;
      });
    },
  },
  methods: {
    addNetwork(inputs) {
      this.add_network.loading = true;
      this.add_network.submitted = true;
      return Api.plugSendCommand(this.role, {
        plugIdentifier: this.device.identifier, 
        command: this.commands.WIFI_ADD_NETWORK,
        data: {
          ssid: inputs.ssid,
          password: String(inputs.password),
          securityType: inputs.security,
          securityCipher: inputs.encryption,
          channel: inputs.channel,
        },
      })
        .then((response) => {
          this.add_network.success = true;
          this.getStoredNetworks();
        })
        .catch((error) => {
          console.log(error);
          this.add_network.success = false;
        })
        .finally(() => (this.add_network.loading = false));
    },
    clearWifi() {
      this.clear_wifi.loading = true;
      this.clear_wifi.submitted = true;
      return Api.plugSendCommand(this.role, {
        plugIdentifier: this.device.identifier,  
        command: this.commands.WIFI_CLEAR_STORED_NETWORKS,
      })
        .then((response) => {
          this.clear_wifi.success = true;
          this.getStoredNetworks();
        })
        .catch((error) => {
          console.log(error);
          this.clear_wifi.success = false;
        })
        .finally(() => (this.clear_wifi.loading = false));
    },
    getConnectedNetwork() {
      this.loadings.connected_network = true;
      return Api.plugSendCommand(this.role, { plugIdentifier: this.device.identifier, command: this.commands.WIFI_GET_CONNECTED_NETWORK })
        .then((data) => (this.datas.connected_network = data.connectedNetwork))
        .catch((error) => {
          console.log(error);
          this.is_device_offline = true;
        })
        .finally(() => (this.loadings.connected_network = false));
    },
    getStoredNetworks() {
      this.loadings.stored_networks = true;
      this.datas.stored_networks = [];
      return Api.plugSendCommand(this.role, { plugIdentifier: this.device.identifier, command: this.commands.WIFI_GET_STORED_NETWORKS })
        .then((data) => {
          const { data: stored_network_datas, header } = data;
          for (let i = 0; i < stored_network_datas.length; i++) {
            const parsedData = header.reduce((parsed, key) => {
              let value = stored_network_datas[i][header.indexOf(key)]
              if (key === "security") {
                const selected_security = this.variables.networks.securities.find((security) => security.data == value);
                value = selected_security ? selected_security.name : null;
              }
              parsed[key] = value;
              return parsed;
            }, {})

            this.datas.stored_networks.push(parsedData);
          }
        })
        .catch((error) => {
          console.log(error);
          this.is_device_offline = true;
        })
        .finally(() => (this.loadings.stored_networks = false));
    },
    testConnection() {
      this.busy = true;
      this.is_device_offline = true;
      return Api.plugSendCommand(this.role, { plugIdentifier: this.device.identifier, command: this.commands.DEVICE_VERIFY_CONNECTION })
        .then((response) => {
          this.is_device_offline = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.busy = false));
    },
    rebootDevice() {
      this.show_reboot_dialog = true; 
      this.busy = true;
      this.is_device_offline = true;
      return Api.plugSendCommand(this.role, { plugIdentifier: this.device.identifier, command: this.commands.DEVICE_REBOOT })
        .then((response) => {
          this.$store.dispatch("snackbar", {
            message: `Reboot request sent to ${this.device.identifier}`,
          });
        })
        .catch((err) => {
          this.$store.dispatch("snackbar", {
            message: `Reboot failed`,
          });
          this.error = true; 
        })
        .finally(() => (this.busy = false));
    }
  },
};
</script>
<style lang="scss">
.border-0 {
  border: 0px;
}
.bordered-top {
  border-top: 1px solid #f8f8f8;
}
.bordered-bottom {
  border-bottom: 1px solid #f8f8f8;
}
.rounded {
  border-radius: 5px;
}
.overflow-hidden {
  overflow: hidden;
}
.bg-default {
  background: #f8f8f8;
}
.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
.network-management {
  &__details {
    font-size: 0.75em;
    color: gray;
    color: #949fa8;
    font-weight: 400;
    padding-left: 16px;
    line-height: 1.8em;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
