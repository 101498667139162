<template>
  <div class="center-container mt-5"> 
    <v-card :style="{ backgroundColor: darkTheme ? '#212121' : 'initial' }" class="v-card">
      <v-card-title v-if="extendStep === 1">
        <h3>Purchase Summary</h3>
      </v-card-title>
      <v-alert :value="error" type="error">
        {{ error }}
      </v-alert>
      <v-card-text v-if="extendStep === 0">
        <v-form ref="addSubscriptionForm">
          <h3 class="text-xs-left">Pay From:</h3>
            <v-tooltip
              bottom  
              :offset-y="-10" 
              class="inline-tooltip"                
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="tooltip-icon mb-0 pull-right"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span class="tooltip-content">
                Payments in USD or CAD currencies only
              </span>
            </v-tooltip>
            <v-autocomplete
              v-model="inputs.wallet"
              :items="specificWallets"
              item-text="name"
              item-value="id"
              label="Wallet"
              outline
              autocomplete="false"
              required
              :rules="[rules.required]"
              :error="!!error"
              :disabled="loading"
              hint="Payments in USD or CAD currencies only"
              persistent-hint
              class="mt-1"
            />
          <h3 class="text-xs-left mt-3">Duration:</h3> 
            <v-text-field
              v-model.number="inputs.months"
              type="number"
              solo
              autocomplete="false"
              min="1"
              required
              :rules="[rules.oneOrMore]"
              :error="!!error"
              :disabled="loading"
              persistent-hint
              suffix=" x 30 Days"
              class="mt-2 solo-button"
            />
        </v-form>
      </v-card-text>
      <v-card-text v-if="extendStep === 1" class="text-xs-left">
        <p class="bill-summary-new">
          Device ID: <strong class="bill-summary-new">{{ selectedPlugExtend.slice().sort().join(', ') }}</strong>
          <br />
          Wallet: <strong class="bill-summary-new">{{ selectedWallet ? selectedWallet.name : "" }}</strong>
          <br />
          Wallet Balance:  <strong class="bill-summary-new"> $ {{ selectedWallet ? formatValue(selectedWallet.balance.toFixed(2)) : "0.00" }} </strong>
          <br />
          Currency : <strong class="bill-summary-new">{{ selectedWallet ? selectedWallet.currency : "" }} </strong>
          <br />
          Purchased Days : <strong class="bill-summary-new">{{ inputs.months * 30 }} Days</strong>
        </p>
        <h3 class="total-summary-note" style="text-align: left;">
          <span v-if="!totalBilling.insufficient" style="display: inline-block;">Total:</span>
          <span class="total-summary-note" style="display: inline-block; text-align: left; margin-left: 4px;">
            <strong :class="totalBilling.insufficient ? 'error--text' : 'green--text'" class="total-summary-note">
              {{ totalBilling.insufficient ? totalBilling.errorMessage : `$ ${formatValue(totalBilling.total.toFixed(2))} ${selectedWallet ? selectedWallet.currency : ""}` }}
            </strong>
          </span>
        </h3>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="extendStep === 0"
          color="gray"
          flat="flat"
          @click="
            $emit('extendGoBack')
          "
        >
          Back
        </v-btn>
        <v-btn v-else @click="$emit('extendCancel')" color="gray" flat="flat">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="extendStep === 0"
          color="green darken-1"
          flat="flat"
          :loading="busy"
          :disabled="!inputs.wallet || !inputs.months"
          @click.stop.prevent="$emit('extendGoNext')"
        >
          NEXT
        </v-btn>
        <v-btn v-if="extendStep === 1 && !totalBilling.insufficient && selectedPlugExtend.length > 0"
          color="green darken-1"
          flat="flat"
          :loading="busy"
          @click.stop.prevent="$emit('extendConfirm', { inputs, total: totalBilling })"
        >
          CONFIRM
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template> 

<script>
import mixin from "@/mixins";
import sort from "@/mixins/sort";
import { mapState, mapGetters } from "vuex";

export default {
  mixins: [mixin, sort],
  props: {
    extendStep: {
      type: Number,
      default: 0
    },
    dataplans: {
      type: Array,
      default: () => ([])
    }, 
    selectedPlugExtend: { 
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      error: false,
      busy: false,
      loading: false,
      inputs: {
        months: null,
        wallet: null,
      }
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Wallet", {
      specificWallets: "specificWallets",
    }),
    totalBilling() {
      const rate = this.dataplans.map(plan => (this.selectedWallet || {}).currency === "USD" ? plan.cost_usd : plan.cost_cad).reduce((v, current) => v + current, 0)
      const total = this.inputs.months * rate; 
    
      let errorMessage = null; 
      let insufficient = false; 
      if (this.selectedWallet && total > this.selectedWallet.balance) { 
        errorMessage = "Wallet has insufficient funds. Please top up the selected wallet in the 'Wallets' page or go back and select a new wallet"
        insufficient = true; 
      }
      return { 
        total, 
        errorMessage,
        insufficient
      } 
    },
    selectedWallet() {
      return this.specificWallets.find(w => w.id === this.inputs.wallet)
    },
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    },
  },
  methods: {
    formatValue(num) {
      if (!num) return 0;
      var parts = num.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    },
  }
};
</script>

<style lang="scss" scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; 
  margin: 0 auto; 
  padding: 16px;
}

.bill-summary-new { 
  font-size: small
}

.v-card {
  width: 300px;
  height: max-content;
}

.total-summary-note { 
  font-size: medium;  
}

.inline-tooltip {
  display: inline-block; 
  position: relative; 
}
.tooltip-icon {
  position: absolute;
  top: 15px; 
  right: 10px; 
}
</style>