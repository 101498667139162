<template>
  <v-container fluid>
    <v-layout row wrap align-center>
      <v-flex xs4 sm6 class="text-xs-left page-toolbar">
        <h1 class="d-inline-block" style="font-size: 25px;">Device Management &gt; Network Configuration</h1>
      </v-flex>
      <v-flex xs8 sm6 class="text-xs-right page-toolbar">
        <MergedRegisterPlug class="ml-3 mr-3 d-inline-block" />
      </v-flex>
    </v-layout>

    <v-layout row wrap class="mt-2">
      <v-flex md6 xs12>
        <TotalPlugPieGraph
          :colors="['#0d920d', '#f09000']"
          :labels="['WiFi', 'Cellular']"
          :tooltip="tooltips.totalGraph"
          :totals="totals"
          :allTotal="totals.reduce((a, b) => a + b)"
        />
      </v-flex>
      <v-flex md6 xs12>
        <v-layout col wrap>
          <v-flex xs12>
            <ConfigureWifiDevice :devices="datas.plugs.filter((device) => device.communication_type == 'WiFi')" class="ma-2" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="mt-10">
      <v-flex xs12>
        <v-layout row class="mt-0 mb-0">
          <v-flex sm4 xs5>
            <RegexTextfield
              v-model="filters.search"
              :enable-regex="filters.enableRegexSearch"
              @regexToggled="(v) => (filters.enableRegexSearch = v)"
              :disabled="$store.state.loading"
              prepend-icon="search"
            />
          </v-flex>
          <v-flex sm8 xs7 class="text-xs-right pt-3 pr-2" v-if="$store.state.loading">
            <v-progress-circular indeterminate color="orange" :size="24" />
          </v-flex>
          <v-flex sm8 xs7 class="text-xs-right page-toolbar pt-3" v-else>
            <v-menu offset-y>
              <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
                <span class="hidden-sm-and-down">
                  Device Type: &nbsp;
                  <strong>{{ filters.communication_type.label }}</strong>
                </span>
                <v-icon class="hidden-md-and-up">settings</v-icon>
              </v-btn>
              <v-list hover>
                <v-list-tile
                  class="pointer"
                  :class="{ 'menu-active': filters.communication_type.property === item.property }"
                  v-for="(item, index) in variables.communication_types"
                  :key="index"
                  @click="filters.communication_type = item"
                >
                  <small>{{ item.label }}</small>
                </v-list-tile>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
                <span class="hidden-sm-and-down">
                  Power Settings: &nbsp;
                  <strong>{{ filters.power_setting.label }}</strong>
                </span>
                <v-icon class="hidden-md-and-up">settings</v-icon>
              </v-btn>
              <v-list hover>
                <v-list-tile
                  class="pointer"
                  :class="{ 'menu-active': filters.power_setting.property === item.property }"
                  v-for="(item, index) in variables.power_settings"
                  :key="index"
                  @click="filters.power_setting = item"
                >
                  <small>{{ item.label }}</small>
                </v-list-tile>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <v-btn slot="activator" value="hideDevice" small flat class="page-toolbar-btn">
                <span class="hidden-sm-and-down">
                  Show Hidden Devices: &nbsp;
                  <strong>{{ filters.showHiddenDevice ? "Yes" : "No" }}</strong>
                </span>
                <v-icon class="hidden-md-and-up">hide_source</v-icon>
              </v-btn>
              <v-list hover>
                <v-list-tile
                  class="pointer"
                  :class="{ 'menu-active': filters.showHiddenDevice === item.property }"
                  v-for="(item, index) in filters.hiddenOptions"
                  :key="index"
                  @click="filters.showHiddenDevice = item.property"
                >
                  <small>{{ item.label }}</small>
                </v-list-tile>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
                <span class="hidden-sm-and-down">
                  Sort: &nbsp;
                  <strong>{{ sorts.by.label }}</strong>
                </span>
                <v-icon class="hidden-md-and-up">settings</v-icon>
              </v-btn>
              <v-list hover>
                <v-list-tile 
                  class="pointer"
                  :class="{ 'menu-active': sorts.by.property === item.property }"
                  v-for="(item, index) in variables.sorts"
                  :key="index"
                  @click="sorts.by = item"
                >
                  <small>{{ item.label }}</small>
                </v-list-tile> 
              </v-list>
            </v-menu>
            <v-btn flat small class="page-toolbar-btn" @click="sorts.desc = !sorts.desc" :disabled="!sorts.by.property">
              <v-icon :class="{ 'rotate-icon': !sorts.desc }">sort</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
        <DeviceNetworkTile 
          v-for="(device, index) in filteredPlugs"
          :device="device"  
          :key="device.identifier" 
          :is-hidden="filters.showHiddenDevice ? hiddenDevices.includes(device.identifier) : false"
        />
    </v-layout>
  </v-container>
</template>

<script>
import Api from "@/library/apis/Api";
import PlugHelpers from "@/library/helpers/PlugHelpers";
import { mapState, mapGetters } from "vuex";
import { ApiHelpers } from '@/library/helpers';
import TotalPlugPieGraph from "@/../src/components/graphs/TotalPlugPieGraph.vue";
import MergedRegisterPlug from "@/components/mergedRegisterPlug/MergedRegisterPlug";
import RegexTextfield from "@/components/inputs/RegexTextfield";
import ConfigureWifiDevice from "./ConfigureWifiDevice";
import DeviceNetworkTile from "./DeviceNetworkTile.vue";

export default {
  name: "DeviceNetworkManagement",
  components: {
    RegexTextfield,
    ConfigureWifiDevice,
    DeviceNetworkTile,
    MergedRegisterPlug,
    TotalPlugPieGraph,
  },
  data() {
    return {
      datas: {
        plugs: [],
      },
      filters: {
        communication_type: {
          label: "All",
          property: "all",
        },
        power_setting: {
          label: "All",
          property: "all",
        },
        showHiddenDevice: false, 
        hiddenOptions: [
          {
            label: "Yes",
            property: true,
          },
          {
            label: "No", 
            property: false,
          },
        ],
        search: null,
        enableRegexSearch: false,
      },
      sorts: {
        desc: false,
        by: {
          label: "Device ID",
          property: "identifier",
        },
      },
      variables: {
        communication_types: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "WiFi",
            property: "WiFi",
          },
          {
            label: "Cellular",
            property: "Cellular",
          },
        ],
        power_settings: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "Always Off",
            property: 0,
          },
          {
            label: "Always On",
            property: 1,
          },
          {
            label: "Smart",
            property: 2,
          },
          // comented to hide Bluetooth Property
          // {
            // label: "Bluetooth",
            // property: 3,
          // },
        ],
        sorts: [
          {
            label: "Device ID",
            property: "identifier",
          },
          {
            label: "Description",
            property: "description",
          },
          {
            label: "Type",
            property: "communication_type",
          },
        ],
      },
      tooltips: {
        totalGraph: `
            <strong>Total registered devices and their type</strong>
            <ul class="ma-0 mt-2 ml-3 pa-0">
              <li class="mb-2">Cellular: Devices that contain a cellular modem and connect to the cloud by cellular data</li>
              <li class="mb-2">WiFi: Devices that contain a WiFi modem and connect to the cloud by a WiFi network</li>
            </ul>`,
      },
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview
    }),
    ...mapGetters("Device", { 
      hiddenDevices: "hiddenDevices",
      visibleDevices: "visibleDevices"
    }),
    ...mapGetters("Admin", {
      viewAsOwner: "viewAsOwner"
    }),
    filteredPlugs() {
      const filters = {
        communication_type: this.filters.communication_type.property == "all" ? "all" : this.filters.communication_type.property,
        power_setting: this.filters.power_setting.property == "all" ? "all" : parseInt(this.filters.power_setting.property),
        sort_by: this.sorts.by.property,
        sort_direction_desc: this.sorts.desc,
        search: this.filters.search,
        enableRegexSearch: this.filters.enableRegexSearch,
      };
      let plugs = this.datas.plugs
      const hiddenDevices = this.hiddenDevices;
      if (!this.filters.showHiddenDevice) { 
        plugs = plugs.filter(p => !hiddenDevices.includes(p.identifier));
      }
      return PlugHelpers.filter(plugs, filters);
    },
    plugs() {
      return this.$store.getters.plugs;
    },
    totals() {
      const wifi = this.filteredPlugs.filter((plug) => plug.communication_type == "WiFi");
      const cellular = this.filteredPlugs.filter((plug) => plug.communication_type == "Cellular");
      return [wifi.length || 0, cellular.length || 0];
    }
  },
  watch: {
    plugs() {
      this.datas.plugs = JSON.parse(JSON.stringify(this.plugs));
    },
  },
  mounted() {
    this.$store.dispatch("loading", true);
    const usernamePayload = ApiHelpers.getUsernamePayloadOfViewAs(this.adminActiveView, this.viewAsOwner);
    Api.plugs(ApiHelpers.getApiPrefix(this.role, this.adminActiveView),{...usernamePayload})
      .then((plugs) => {
        if ((plugs || []).length === 0) {
          this.$router.push(`${this.role === "admin" && !!this.managerview ? "/admin/managerview-" : "/owner/"}outlet-management`)
        }
        this.$store.dispatch("setPlugs", plugs || []);
      })
      .catch((error) => console.log(error))
      .finally(() => this.$store.dispatch("loading", false));
  }
};
</script>
