<template> 
  <div class="center-container mt-5">
    <v-card :style="{ backgroundColor: darkTheme ? '#212121' : 'initial' }" class="v-card">
      <v-tooltip
        v-if="stepAutoPayment === 0"
        bottom  
        :offset-y="-10"                
      >
        <template v-slot:activator="{ on }">
          <v-icon
            class="tooltip-icon mb-5 mt-3 mr-2 pull-right"
            v-on="on"
          >
            info
          </v-icon>
        </template>
          <span class="tooltip-content">
            {{ tooltips.dropdown }} 
          </span>
      </v-tooltip>
      <v-card-title v-if="stepAutoPayment === 0" :style="{ backgroundColor: darkTheme ? '#212121 !important' : 'initial' }">
        <h3>Pay From: </h3>
      </v-card-title>
      <v-card-title v-else-if="stepAutoPayment === 1" :style="{ backgroundColor: darkTheme ? '#212121 !important' : 'initial' }">
        <h3>Auto Payment</h3>
      </v-card-title>
      <template>
        <v-card-text v-if="stepAutoPayment === 0">
          <v-form ref="setAutoForm">
            <v-autocomplete
              v-if="inputs.autoPaymentDataDisabled && stepAutoPayment === 0"
              v-model="inputs.autoPaymentWallet"
              :items="specificWallets"
              item-text="name"
              item-value="id"
              label="Wallet"
              outline
              autocomplete="false"
              disabled
              hint="Payments in USD or CAD currencies only"
              persistent-hint
              class="mt-3"
            />
            <v-autocomplete
              v-if="!inputs.autoPaymentDataDisabled && stepAutoPayment === 0" 
              v-model="inputs.autoPaymentWallet"
              :items="specificWallets"
              item-text="name"
              item-value="id"
              label="Wallet"
              outline
              autocomplete="false"
              required
              :rules="[rules.required]"
              :error="!!error"
              :disabled="loading || inputs.autoPaymentDataDisabled"
              hint="Payments in USD or CAD currencies only"
              persistent-hint
              class="mt-3"
            ></v-autocomplete>
          </v-form>
          <v-divider class="mt-4 mb-0"></v-divider>
          <div class="wallet-dialog-separator">
            OR
          </div>
          <v-checkbox class="mt-4" v-model="inputs.autoPaymentDataDisabled" required color="success" hide-details>
            <template v-slot:label>
              Disable Auto-payment
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-text>
          <div v-if="inputs.autoPaymentDataDisabled && stepAutoPayment === 1">
            <ul class="summary-note text-xs-left">
              <div class="text-xs-center">
                <v-icon color="orange" size="32">warning</v-icon>
              </div>
              <li v-for="(note, index) in expiryNotes" :key="index">{{ note }}</li>
            </ul>
          </div>
          <div v-if="!inputs.autoPaymentDataDisabled && stepAutoPayment === 1">
            <p class="bill-summary text-xs-left">
              Device ID: <strong>{{ selectedPlugAuto.slice().sort().join(', ') }}</strong>
              <br />
              Wallet: <strong> {{ selectedWallet ? selectedWallet.name : ""  }} </strong>
              <br />
              Wallet Balance: <strong>$ {{ selectedWallet ? formatValue(selectedWallet.balance.toFixed(2)) : "0.00" }} </strong>
              <br />
              Currency: <strong>{{ selectedWallet ? selectedWallet.currency : "" }}</strong>
              <br />
              <span v-if="!totalBilling.insufficient" class="bill-summary" style="display: inline-block;">Amount:</span>
              <span class="total-bill-summary" style="display: inline-block; margin-left: 4px; margin-top: 4px;">
                <strong :class="totalBilling.insufficient ? 'error--text' : 'green--text'" class="total-bill-summary">
                  {{ totalBilling.insufficient ? totalBilling.errorMessage : `$ ${formatValue(totalBilling.total.toFixed(2))} ${selectedWallet ? selectedWallet.currency : ""} / 30 Days` }}
                </strong>
              </span>
            </p>
            <div class="summary-note text-xs-left"><p>Subscription will automatically be renewed when the device has &lt;30 days to expiry.</p></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="stepAutoPayment === 1"
            color="gray"
            flat="flat"
            @click="
              error = false;
              $emit('setAutoBackButton')
            "
          >
            Back
          </v-btn>
          <v-btn 
            v-if="stepAutoPayment === 0" 
            color="gray" 
            flat="flat" 
            @click="$emit('setAutoCancelButton')"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            v-if="stepAutoPayment === 0" 
            color="green darken-1" 
            flat="flat" 
            :disabled="inputs.autoPaymentWallet === null && !inputs.autoPaymentDataDisabled"
            :loading="busy" 
            @click.stop.prevent="$emit('setAutoNextButton')"
          >
            NEXT 
          </v-btn>
          <v-btn 
            v-if="stepAutoPayment === 1 && !totalBilling.insufficient && selectedPlugAuto.length > 0" 
            color="green darken-1" 
            flat="flat" 
            :loading="busy" 
            @click.stop.prevent="$emit('confirmAutoPayment', {inputs})"
          >
            CONFIRM
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </div>
</template> 

<script>
import mixin from "@/mixins";
import sort from "@/mixins/sort";
import { mapState, mapGetters } from "vuex";

export default {
  mixins: [mixin, sort],
  props: { 
    stepAutoPayment: { 
      type: Number, 
      default: 0
    }, 
    selectedPlugAuto: { 
      type: Array, 
      default: () => ([]) 
    },
    dataplans: { 
      type: Array, 
      default: () => ([])
    }
  },
  components: {},
  data() {
    return {
      inputs : { 
        autoPaymentWallet: null, 
        autoPaymentDataDisabled: false, 
        months: null, 
      },
      error: false,
      loading: false,
      busy: false,
      expiryNotes: [
        "Devices will automatically be disabled anytime after the subscription has expired.",
        "There is a reactivation fee for reactivating disabled devices.",
        "Reactivating disable devices will take between 3 - 5 business days.",
        "Disabled devices will retain their initial mode right before they were disabled.",
        "You will no longer be able to control disabled devices in the Device Management page.",
        'No one will be able to access/use disabled devices unless the device was previously set to the "Always On" power setting.',
      ],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Wallet", {
      specificWallets: "specificWallets",
    }),
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    },
    totalBilling() {
      const rate = this.dataplans.map(plan => (this.selectedWallet || {}).currency === "USD" ? plan.cost_usd : plan.cost_cad).reduce((v, current) => v + current, 0)
      const total = rate; 

      let errorMessage = null; 
      let insufficient = false; 
      if (this.selectedWallet && total > this.selectedWallet.balance) { 
        errorMessage = "Wallet has insufficient funds. Please top up the selected wallet in the 'Wallets' page or go back and select a new wallet"
        insufficient = true; 
      }
      return { 
        total, 
        errorMessage,
        insufficient
      } 
    },
    selectedWallet() { 
      return this.specificWallets.find(w => w.id === this.inputs.autoPaymentWallet)
    },
    tooltips() {
      return {
        dropdown: `Payments in USD or CAD currencies only`,
      };
    },
  },
  methods: {
    formatValue(num) {
      if (!num) return 0;
      var parts = num.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    },
  }
};
</script>

<style lang="scss" scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  margin: 0 auto;
  padding: 70px; 
}

.total-bill-summary { 
  line-height: 1.8em;
  font-size: medium;
}

.bill-summary {
  line-height: 1.8em;
  font-size: small;
}

.summary-note {
  font-size: 0.9em;
  padding: 1em;
  background: #f8f8f8;
  li {
    margin: 0.5em 0.5em 0.5em 2em;
  }
}
.v-card { 
  max-width: 400px;
  width: auto;
  height: max-content;
}
</style>