<template> 
  <div class="center-container mt-5 flex"> 
    <v-card :style="{ backgroundColor: darkTheme ? '#212121' : 'initial' }">
      <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-flex class="justify-center align-center">
        <v-card-title class="bold-text text-xs-left">
          {{ step === 0 ? "Please provide us the currency you would to pay the reactivation fee in (USD or CAD only)" 
          : step === 1 ? "Enter the amount of subscription time you would like to add to the device(s)" 
          : ''}}
        </v-card-title>
          <v-select 
            v-if="step === 0"
            v-model="currencyValue"
            id="currency-input"
            type="select"
            :items="[
              { value: 'USD', text: 'United States (USD)' },
              { value: 'CAD', text: 'Canada (CAD)' }
            ]"
            label="Currency"
            :rules="[validators.required]"
            outline
            @input="$emit('update:inputCurrency', $event)"
          />
          <v-text-field
            v-if="step === 1" 
            v-model.number="timeValue"
            id="time-input"
            type="number"
            outline
            autocomplete="false"
            :rules="[validators.required, validators.maxNumber, validators.minNumber, validators.noDecimalNumber]"
            label="Total"
            @input="$emit('update:inputTime', $event)"
          >
            <template #append-outer v-if="step === 1">
              <h3 class="font-weight-bold">
                Months
              </h3>
            </template>
          </v-text-field>
          <ul 
            v-if="!feedbackSent"  
            class="summary-note mt-2 text-xs-left">
            <div class="text-xs-center">
              <v-icon color="orange" size="32">warning</v-icon>
            </div>
            <li>
              Devices will automatically be disabled anytime after the subscription has expiried.
            </li>
            <li>
              There is a reactivation fee for reactivating disabled devices.
            </li>
            <li>
              Reactivating disable devices will take between 3 - 5 business days.
            </li>
            <li>
              Disabled devices will retain their initial mode right before they were disabled.
            </li>
            <li>
              You will no longer be able to control disabled devices in the Device Management page.
            </li>
            <li>
              No one will be able to access/use disabled devices unless the device was previously set to the "Always On" power setting.
            </li>
          </ul>
        </v-flex>
     
      <v-divider class="ma-auto"></v-divider>
      <v-card-actions fixed>
        <v-btn
          id="cancel" 
          color="gray" 
          flat="flat" 
          @click="$emit('cancel')"  
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          v-if="step === 0"
          id="next"
          color="green darken-1"
          flat="flat"
          :disabled="!['USD', 'CAD'].includes(currencyValue)"
          @click="$emit('next')"
        >
          Next
        </v-btn>
        <v-btn 
          v-if="step === 1"
          id="feedback-submit" 
          color="green darken-1" 
          flat="flat" 
          :disabled="!timeValue" 
          @click="$emit('submit')"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template> 
<script>
import validators from '@/library/helpers/validators';

export default {
  props: {
    step: {
      type: Number,
      default: 0
    },
    feedbackSent: {
      type: Boolean, 
      default: false
    },
    inputCurrency: {
      type: String,
    },
    inputTime: { 
      type: Number, 
      default: null
    },
  },
  data() {
    return {
      currencyValue: this.inputCurrency,
      timeValue: this.inputTime,
      error: false,
    };
  },
  computed: {
    validators: () => validators,
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    },
  },
};
</script>

<style lang="scss" scoped>
.bold-text {
  font-weight: bold;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto; 
  padding: 20px;
}
.summary-note {
  font-size: 1em;
  padding: 1em;
  background: #f8f8f8;
  li {
    margin: 1em 1em 1em 4em;
  }
}
</style>