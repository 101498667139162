<template>
  <v-layout row wrap v-if="storedPlugs">
    <v-flex xs3 class="text-xs-left page-toolbar">
      <h1 id="title" class="d-inline-block mt-2 white-space__nowrap" style="font-size: 25px;">{{ title }}</h1>
      <v-flex v-if="isDeviceRegistered" align-center justify-start w-full style="display: flex">
        <TransferDeviceModal v-if="activeAdminView === 'deviceview'" class="ml-3 d-inline-block" />
        <UnregisterDeviceModal v-if="activeAdminView === 'deviceview'" class="ml-3 d-inline-block" @unregistered="() => $router.push('deviceview')" />
      </v-flex>
    </v-flex>
    <v-flex xs9 class="text-xs-right page-toolbar">
      <ExportData :filters="filters" class="ml-3 mt-2 d-inline-block" />
      <DateRange v-if="isDeviceRegistered" class="date-range ml-2 d-inline-block" style="margin: 5px;" :with-timezone="role == 'admin'" timezone-text-position="left" />
    </v-flex>

    <template v-if="isDeviceRegistered">
      <v-flex lg5 sm12>
        <SessionHeatmapTile :filters="filters" />
        <StatsTotals v-if="responsive" />
      </v-flex>
      <v-flex lg7 sm12>
        <StatsTotals v-if="!responsive" />
      </v-flex>
      <v-flex xs12 class="pt-0 mt-0">
        <OwnerSessions @filters="(e) => (filters = e)" />
      </v-flex>
    </template>
    <v-flex v-else xs12 class="pt-0 mt-0">
      <DeviceNotRegisteredInfo />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DateRange from "@/components/modals/DateRange";
import StatsTotals from "@/components/graphs/StatsTotals";
import OwnerSessions from "@/components/OwnerSessions";
import ExportData from "@/components/modals/ExportData";
import UnregisterDeviceModal from "@/components/modals/UnregisterDeviceModal.vue";
import TransferDeviceModal from "@/components/modals/TransferDeviceModal.vue";
import DeviceNotRegisteredInfo from "@/components/DeviceNotRegisteredInfo.vue";
import SessionHeatmapTile from "@/components/graphs/SessionHeatmapTile.vue";

export default {
  components: {
    DateRange,
    StatsTotals,
    OwnerSessions,
    ExportData,
    SessionHeatmapTile,
    UnregisterDeviceModal,
    TransferDeviceModal,
    DeviceNotRegisteredInfo,
  },
  data() {
    return {
      filters: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      responsive: (state) => state.responsive,
      role: (state) => state.Auth.role,
      storedPlugs: (state) => state.plugs,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters("Admin", {
      deviceview: "formattedDeviceView",
      viewAsOwner: "viewAsOwner",
      isDeviceRegistered: "isDeviceRegistered",
    }),
    title() {
      if (this.role == "admin") {
        if (this.activeAdminView == "deviceview" && !!this.deviceview) return `${this.deviceview.identifier} > Dashboard`;
        if (this.activeAdminView == "managerview" && !!this.viewAsOwner) return `${this.viewAsOwner.username} > Dashboard`;
      }
      return "Dashboard";
    },
  },
  mounted() {
    if (this.role == "admin" && !this.viewAsOwner) return this.$router.push(`/admin/${this.activeAdminView}`);
  },
};
</script>

<style lang="scss" scoped>
.session-tile {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.expiry-tooltip {
  position: absolute !important;
  left: 106px;
  top: 16px;
  font-size: 24px !important;
  opacity: 1 !important;
  z-index: 102;
}
</style>
