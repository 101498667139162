<template>
  <v-card class="access-tile">
    <v-card-title @click.stop="allowConfigureNetwork ? show = !show : null">
      <h3>Configure Multiple WiFi Devices</h3>
      <v-icon
        class="tooltip-icon ml-2"
        v-tooltip="{
          content: 'Add or remove networks from 1 or more of your WiFi devices.',
          trigger: 'click hover',
        }"
        >info</v-icon
      >
      <v-spacer></v-spacer>
      <v-btn flat small icon class="ma-0" @click.stop="show = !show" :disabled="!allowConfigureNetwork">
        <v-icon v-if="show">keyboard_arrow_up</v-icon>
        <v-icon v-else>keyboard_arrow_down</v-icon>
      </v-btn>
    </v-card-title>
    <div v-if="show">
      <v-alert :value="error" type="error" dismissible @input="error = null">{{ error }}</v-alert>
      <v-card-text class="pb-0">
        <v-flex v-if="!!loading" class="flex flex-column justify-center align-center text-xs-center">
          <v-progress-circular class="loading-circle mt-4" size="40" indeterminate color="orange" />
          <div class="my-4">
            <h2>{{ loading }}</h2>
          </div>
        </v-flex>
        <div v-else-if="menu == null">
          <v-select
            class="mt-3"
            v-model="selectedAction"
            :items="variables.actions"
            item-text="label"
            item-value="value"
            label="Action"
            outline
            required
            :error="error ? true : false"
            :disabled="!!loading"
            persistent-hint
          ></v-select>
        </div>
        <div v-else-if="menu == 'select-device'">
          Action: <strong>{{ selectedAction == "add" ? "Add a WiFi network" : "Clear all WiFi networks" }}</strong>
          <div>
            <RegexTextfield
              v-model="search"
              :enable-regex="enableRegex"
              @regexToggled="(v) => (enableRegex = v)"
              :disabled="$store.state.loading"
              prepend-icon="search"
            ></RegexTextfield>
          </div>
          <div class="text-xs-right mb-2 p-relative" v-if="filteredDevices.length > 0">
            <a class="d-inline-block mr-2 select-all" @click.stop.prevent="selectAll = !selectAll" :key="selectAll">{{
              selectAll ? "Select None &nbsp;&nbsp;" : "Select All &nbsp;&nbsp;"
            }}</a>
            <v-checkbox class="d-inline-block mt-0 mb-0 mr-1" style="padding-right: 1px" color="green" v-model="selectAll" :disabled="!!loading" hide-details />
          </div>
          <div v-else class="modal-description mt-1 mb-3">No devices found</div>
          <v-list v-if="filteredDevices" class="pa-0 ma-0 mt-0 mb-2 access-tile-plugs">
            <v-list-tile v-for="(device, i) in filteredDevices" :key="i" class="ma-0 access-tile-plugs-list" @click="(evt) => clickCheckbox(evt, i)">
              <v-list-tile-content>
                <v-list-tile-title class="user-name">{{ device.identifier + " - " + device.description }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action class="text-xs-right">
                <div style="checkbox-container">
                  <v-checkbox :id="`checkbox_${i}`" color="green" v-model="device.selected" @change="select(device)" :disabled="!!loading" hide-details />
                </div>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </div>
        <v-flex xs12 v-else-if="menu == 'add-wifi-input'">
          <v-layout xs12 justify-center>
            <v-flex xs12>
              <AddNetworkForm
                @cancelled="back()"
                @submitted="(inputs) => addNetwork(false, inputs)"
                cancel-button-text="Back"
                cancel-button-class="v-btn--outline v-btn--depressed v-btn--round v-btn--small theme--light"
                confirm-button-class="v-btn--outline v-btn--depressed v-btn--round v-btn--small theme--light green--text"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else-if="menu == 'add-wifi-result'">
          <v-layout xs12 justify-center>
            <v-flex xs12>
              <div class="mb-4" v-if="successSubmittedDevices.length > 0">
                <p class="text-xs-left">
                  Network successfullly added from the following devices:
                </p>
                <div class="text-xs-left">
                  <ul>
                    <li v-for="(device, i) in successSubmittedDevices" :key="i">
                      <strong>{{ device }}</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="failedSubmittedDevices.length > 0">
                <p class="text-xs-left"><strong class="error--text">Error:</strong> Failed to add networks from the following devices:</p>
                <div class="text-xs-left">
                  <ul>
                    <li v-for="(device, i) in failedSubmittedDevices" :key="i">
                      <strong>{{ device }}</strong>
                    </li>
                  </ul>
                </div>
                <p class="text-xs-left mt-4">
                  <img src="./../../../assets/warning-owner.png" width="16px" class="mr-2" style="position:relative;top:3px;" />
                  <span>These devices maybe offline or not powered on.</span>
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else-if="menu == 'clear-wifi-confirmation'">
          <v-layout xs12 justify-center>
            <v-flex xs12>
              <div class="text-xs-center mb-2">
                <h3 class="error--text">Warning</h3>
              </div>

              <p class="text-xs-justify">
                This will permanently delete and remove all stored WiFi networks on the device. Once the networks are deleted, the device will no longer attempt to connect to the
                deleted networks on the next reboot or power cycle. This action cannot be undone and networks will have to be manually added back to the device.
              </p>
              <p class="text-xs-left mt-4">Are you sure to proceed?</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else-if="menu == 'clear-wifi-result'">
          <v-layout xs12 justify-center>
            <v-flex xs12>
              <div class="mb-4" v-if="successSubmittedDevices.length > 0">
                <p class="text-xs-left">
                  Network successfullly cleared from the following devices:
                </p>
                <div class="text-xs-left">
                  <ul>
                    <li v-for="(device, i) in successSubmittedDevices" :key="i">
                      <strong>{{ device }}</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="failedSubmittedDevices.length > 0">
                <p class="text-xs-left"><strong class="error--text">Error:</strong> Failed to clear networks from the following devices:</p>
                <div class="text-xs-left">
                  <ul>
                    <li v-for="(device, i) in failedSubmittedDevices" :key="i">
                      <strong>{{ device }}</strong>
                    </li>
                  </ul>
                </div>
                <p class="text-xs-left mt-4">
                  <img src="./../../../assets/warning-owner.png" width="16px" class="mr-2" style="position:relative;top:3px;" />
                  <span>These devices maybe offline or not powered on.</span>
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
      <v-card-actions class="pa-3">
        <template v-if="!loading && menu != 'add-wifi-input'">
          <v-btn v-if="menu == 'clear-wifi-result' && failedSubmittedDevices.length > 0" @click="clearWifi(true)" round outline small>Retry Failures</v-btn>
          <v-btn v-else-if="menu == 'add-wifi-result' && failedSubmittedDevices.length > 0" @click="addNetwork(true)" round outline small>Retry Failures</v-btn>
          <v-btn v-else-if="menu !== null" @click="back()" round outline small>BACK</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" v-if="[null, 'select-device'].includes(menu)" @click="next()" :loading="!!loading" round outline small :disabled="isNextDisabled">NEXT</v-btn>
          <v-btn color="green" v-else-if="menu == 'clear-wifi-confirmation'" @click="clearWifi()" :loading="!!loading" round outline small>CONFIRM</v-btn>
          <v-btn color="green" v-else-if="['clear-wifi-result', 'add-wifi-result'].includes(menu)" @click="reset()" :loading="!!loading" round outline small>DONE</v-btn>
        </template>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { OwnerProxyHelpers } from "@/library/helpers";
import helpers from "@/mixins/helpers";
import Api from "@library/apis/Api";
import AddNetworkForm from "@/components/forms/AddNetworkForm";
import RegexTextfield from "@/components/inputs/RegexTextfield";
export default {
  props: {
    devices: {
      type: Array,
      required: true,
    },
  },
  components: { AddNetworkForm, RegexTextfield },
  mixins: [helpers],
  data() {
    return {
      add_wifi_inputs: null,
      enableRegex: false,
      error: null,
      loading: null,
      menu: null,
      search: null,
      selectAll: false,
      selectedDevices: [],
      selectedAction: null,
      submittedDevices: {
        success: [],
        failed: [],
      },
      show: false,
      variables: {
        actions: [
          {
            label: "Add Network",
            value: "add",
          },
          {
            label: "Clear Network",
            value: "clear",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    filteredDevices() {
      let devices = this.devices.map((device) => {
        device.selected = this.selectAll;
        return device;
      });
      if (this.search) {
        if (!this.enableRegex) {
          let search = this.search.toLowerCase();
          devices = devices.filter((device) => {
            let identifier = device.identifier.toLowerCase();
            let description = device.description.toLowerCase();
            if (identifier.indexOf(search) > -1 || description.indexOf(search) > -1) {
              return devices;
            }
          });
        } else {
          try {
            const search = new RegExp(this.search, "");
            devices = devices.filter((device) => {
              const identifier = device.identifier;
              const description = device.description;
              return identifier.match(search) || description.match(search);
            });
          } catch (error) {
            console.log(error.message);
            devices = [];
          }
        }
      }
      return devices;
    },
    isNextDisabled() {
      if (this.menu == null) return !this.selectedAction;
      else if (this.menu == "select-device") return this.selectedDevices.length == 0;
      return true;
    },
    successSubmittedDevices() {
      if (this.submittedDevices.length == 0) return [];
      return this.filteredDevices.filter((device) => this.submittedDevices.success.includes(device.id)).map((device) => device.identifier);
    },
    failedSubmittedDevices() {
      if (this.submittedDevices.length == 0) return [];
      return this.filteredDevices.filter((device) => this.submittedDevices.failed.includes(device.id)).map((device) => device.identifier);
    },
    allowConfigureNetwork() {
      return OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_NETWORK_CONFIGURE");
    }
  },
  watch: {
    search: function(value) {
      this.selectAll = false;
      this.selectedDevices = [];
      this.filteredDevices = this.filteredDevices.map((device) => {
        device.selected = false;
        return device;
      });
    },
    selectAll() {
      this.$nextTick(() => {
        this.selectAllToggled();
      });
    },
  },
  methods: {
    addNetwork(only_failure = false, inputs = null) {
      const selectedDevices = !only_failure ? this.selectedDevices : this.submittedDevices.failed;
      if (!only_failure) this.add_wifi_inputs = inputs;
      const data = {
        ssid: this.add_wifi_inputs.ssid,
        password: String(this.add_wifi_inputs.password),
        securityType: this.add_wifi_inputs.security,
        securityCipher: this.add_wifi_inputs.encryption,
        channel: this.add_wifi_inputs.channel,
      };
      this.submittedDevices.failed = [];
      this.submittedDevices.success = [];
      if (selectedDevices.length == 0) return;
      this.loading = "Adding Net WiFi Network...";
      for (let i = 0; i < selectedDevices.length; i++) {
        const device_id = selectedDevices[i];
        Api.plugSendCommand(this.role, { plugId: device_id, command: 0, data: data })
          .then((response) => {
            console.log(response);
            this.submittedDevices.success.push(device_id);
          })
          .catch((error) => {
            console.log(error);
            this.submittedDevices.failed.push(device_id);
          })
          .finally(() => {
            if (i == selectedDevices.length - 1) {
              this.loading = null;
              this.menu = "add-wifi-result";
            }
          });
      }
    },
    back() {
      switch (this.menu) {
        case "add-wifi-input":
          this.menu = "select-device";
          break;
        case "select-device":
          this.menu = null;
          break;
        case "clear-wifi-confirmation":
          this.menu = "select-device";
          break;
      }
    },
    clearWifi(only_failure = false) {
      const selectedDevices = !only_failure ? this.selectedDevices : this.submittedDevices.failed;
      this.submittedDevices.failed = [];
      this.submittedDevices.success = [];
      if (selectedDevices.length == 0) return;
      this.loading = "Clearing Networks...";
      for (let i = 0; i < selectedDevices.length; i++) {
        const device_id = selectedDevices[i];
        Api.plugSendCommand(this.role, { plugId: device_id, command: 3 })
          .then((response) => {
            console.log(response);
            this.submittedDevices.success.push(device_id);
          })
          .catch((error) => {
            console.log(error);
            this.submittedDevices.failed.push(device_id);
          })
          .finally(() => {
            if (i == selectedDevices.length - 1) {
              this.loading = null;
              this.menu = "clear-wifi-result";
            }
          });
      }
    },
    clickCheckbox(evt, index) {
      const automaticallyClickCheckbox =
        evt.target.classList.contains("v-list__tile__title") ||
        evt.target.classList.contains("v-list__tile") ||
        evt.target.classList.contains("v-list__tile__action") ||
        evt.target.classList.contains("v-list__tile__content");
      if (automaticallyClickCheckbox) document.getElementById(`checkbox_${index}`).click();
    },
    next() {
      this.error = null;
      this.loading = null;
      if (this.menu == null) this.menu = "select-device";
      else if (this.menu == "select-device") {
        if (this.selectedAction == "add") this.menu = "add-wifi-input";
        else this.menu = "clear-wifi-confirmation";
      }
    },
    reset(hide_tile = true) {
      this.menu = null;
      this.enableRegex = false;
      this.error = null;
      this.loading = null;
      this.search = null;
      this.selectAll = false;
      this.selectedDevices = [];
      this.selectedAction = null;
      for (let i = 0; i < this.filteredDevices.length; i++) {
        const device = this.filteredDevices[i];
        device.selected = false;
        this.filteredDevices[i] = device;
      }
      if (hide_tile) this.show = false;
    },
    select($evt) {
      let item = $evt.id || $evt.plug_id;
      let select = $evt.selected;
      let index = this.selectedDevices.indexOf(item);
      if (select) {
        if (index === -1) this.selectedDevices.push(item);
      } else {
        this.selectedDevices.splice(index, 1);
      }
    },
    selectAllToggled() {
      this.selectedDevices = [];
      for (let i = 0; i < this.filteredDevices.length; i++) {
        const device = this.filteredDevices[i];
        if (!this.filteredDevices.includes(device)) continue;
        if (this.selectAll) {
          const id = device.id || device.plug_id;
          device.selected = true;
          this.selectedDevices.push(id);
        } else {
          device.selected = false;
        }
        this.filteredDevices[i] = device;
      }
    },
  },
};
</script>
