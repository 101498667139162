<template>
  <v-layout v-if="!!withData" row wrap class="table-container">
    <v-flex v-if="hideSearchBar & !loading && !loadingCombinedPlugs" sm3 xs5 class="text-xs-left page-toolbar">
      <v-text-field
        max-width="300"
        class="session-search mb-0 ml-2"
        v-model.lazy="search"
        :placeholder="!enableRegexSearch ? 'Search' : 'Search RegEx'"
        autocomplete="false"
        :disabled="$store.state.loading"
        prepend-icon="search"
      >
        <template v-if="enableRegexSearch" v-slot:prepend-inner>
          <v-flex>
            <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
          </v-flex>
        </template>
        <template v-slot:append>
          <v-flex align-self-center class="search-append-div">
            <i v-if="enableRegexSearch" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>
            <v-icon
              @click="enableRegexSearch = !enableRegexSearch"
              class="tooltip-icon"
              :color="enableRegexSearch ? `blue lighten-2` : ''"
              v-tooltip="{
                content: tooltips.search,
                trigger: 'hover',
              }"
            >
              settings_applications
            </v-icon>
          </v-flex>
        </template>
      </v-text-field>
    </v-flex>
    <v-flex xs12 v-if="!!loading">
      <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
    </v-flex>

    <v-flex v-if="hideFilter && !loading && !loadingCombinedPlugs" sm9 xs7 class="text-xs-right page-toolbar pt-3">
      <v-menu offset-y>
        <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
          <span class="hidden-sm-and-down">
            Power Settings: &nbsp;
            <strong>{{ filter.status.label }}</strong>
          </span>
          <v-icon class="hidden-md-and-up">settings</v-icon>
        </v-btn>
        <v-list hover>
          <v-list-tile
            class="pointer"
            :class="{ 'menu-active': filter.status.property === item.property }"
            v-for="(item, index) in filter.statuses"
            :key="index"
            @click="filter.status = item"
          >
            <small>{{ item.label }}</small>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <v-btn slot="activator" value="hideDevice" small flat class="page-toolbar-btn">
          <span class="hidden-sm-and-down">
            Show Hidden Devices: &nbsp;
            <strong>{{ filter.showHiddenDevice ? "Yes" : "No" }}</strong>
          </span>
          <v-icon class="hidden-md-and-up">hide_source</v-icon>
        </v-btn>
        <v-list hover>
          <v-list-tile
            class="pointer"
            :class="{ 'menu-active': filter.showHiddenDevice === item.property }"
            v-for="(item, index) in filter.hiddenOptions"
            :key="index"
            @click="filter.showHiddenDevice = item.property"
          >
            <small>{{ item.label }}</small>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-flex>

    <v-flex xs12>
      <div class="elevation-1 ma-0">
        <div class="v-table__overflow">
          <v-flex xs12 v-if="loadingCombinedPlugs" class="d-flex flex-column align-center mt-5">
            <v-progress-circular class="loading-circle margin-center" size="32" indeterminate color="orange" />
          </v-flex>
          <table v-if="!loadingCombinedPlugs && !loading" class="v-datatable v-table" :class="[darkTheme ? 'theme--dark' : 'theme--light']">
            <thead v-if="!loadingCombinedPlugs && !loading">
              <tr>
                <th width="20px" class="column text-xs-center"></th>
                <th
                  class="column text-xs-center sortable"
                  :class="{ active: plugSort.col === 'identifier', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('identifier')"
                >
                  Device ID
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                </th>
                <th
                  class="column text-xs-center sortable"
                  :class="{ active: plugSort.col === 'description', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('description')"
                >
                  Description
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                </th>
                <th
                  class="column text-xs-center sortable"
                  :class="{ active: plugSort.col === 'remaining_raw', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('remaining_raw')"
                >
                  Remaining Days
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                </th>
                <th
                  class="column text-xs-center sortable"
                  :class="{ active: plugSort.col === 'start_timestamp', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('start_timestamp')"
                >
                  Registration Date
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                </th>
                <th
                  class="column text-xs-center p-relative sortable"
                  :class="{ active: plugSort.col === 'expiry_timestamp', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('expiry_timestamp')"
                >
                  Expiry Date
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                  <template>
                    <v-tooltip bottom :offset-y="-10">
                      <template v-slot:activator="{ on }">
                        <v-icon class="tooltip-icon mb-0 pull-left" v-on="on">
                          info
                        </v-icon>
                      </template>
                      <span>
                        <ul>
                          <li>Devices will automatically be disabled anytime after the subscription has expired.</li>
                          <li>There is a reactivation fee for reactivating disabled devices.</li>
                          <li>Reactivating disable devices will take between 3 - 5 business days.</li>
                          <li>Disabled devices will retain their initial mode right before they were disabled.</li>
                          <li>You will no longer be able to control disabled devices in the Device Management page.</li>
                          <li>No one will be able to access/use disabled devices unless the device was previously set to the "Always On" power setting.</li>
                        </ul>
                      </span>
                    </v-tooltip>
                  </template>
                </th>
                <th
                  v-if="hideAutoPayment"
                  class="column text-xs-center sortable"
                  :class="{ active: plugSort.col === 'auto', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('auto')"
                >
                  Auto Payment
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                </th>
                <th
                  v-if="additionalTable"
                  class="column text-xs-center sortable"
                  :class="{ active: plugSort.col === 'cost_usd', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('cost_usd')"
                >
                  USD/30 Days
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                </th>
                <th
                  v-if="additionalTable"
                  class="column text-xs-center sortable"
                  :class="{ active: plugSort.col === 'cost_cad', asc: plugSort.asc, desc: !plugSort.asc }"
                  @click="sortCol('cost_cad')"
                >
                  CAD/30 Days
                  <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                </th>

                <th v-if="hideSelectAll" class="column text-xs-left">
                  <div v-if="hideSelectAll" style="display: flex; align-items: center;">
                    <span class="mr-2">Select All</span>
                    <v-checkbox :disabled="loading" class="d-inline-flex mt-0 pt-0 w-full" style="padding-right: 1px;" color="green" v-model="selectAll" hide-details />
                  </div>
                </th>
              </tr>
            </thead>
            <template v-for="(plug, index) in combinedPlugs">
              <tbody v-if="filter.showHiddenDevice || !hiddenDevices.includes(plug.identifier)" :key="index">
                <tr
                  :class="{
                    'disabled-plugs': plug.state === 0,
                    'selected-plugs': onlyShowSelected,
                  }"
                  @click.stop.prevent="plug.expanded = !plug.expanded"
                >
                  <td><v-icon class="mr-0 log-icon" :color="plug.color">power</v-icon></td>
                  <td>
                    {{ plug.identifier }}
                    <span v-if="hiddenDevices.includes(plug.identifier)">
                      <strong>(Hidden)</strong>
                    </span>
                  </td>
                  <td>{{ plug.description }}</td>
                  <td class="no-wrap">{{ plug.remaining }}</td>
                  <td class="no-wrap">{{ plug.start }}</td>
                  <td class="no-wrap">{{ plug.expiry }}</td>
                  <td v-if="hideAutoPayment">
                    <span :class="plug.auto_class">{{ plug.auto }}</span>
                  </td>

                  <td v-if="allowSelection" class="text-xs-right no-wrap">
                    <v-checkbox
                      class="d-inline-flex mt-3 pt-0 w-full ml-4"
                      style="padding-right: 1px"
                      color="green"
                      v-model="plug.selected"
                      hide-details
                      @click.stop.prevent="toggleCheckbox(plug.identifier)"
                    />
                  </td>
                  <td v-if="additionalDataTable" class="no-wrap">{{ formatCost(plug.dataplan ? plug.dataplan.cost_usd : 0) }}</td>
                  <td v-if="additionalDataTable" class="no-wrap">{{ formatCost(plug.dataplan ? plug.dataplan.cost_cad : 0) }}</td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
    </v-flex>
  </v-layout>
  <v-layout align-center justify-center class="my-5" row wrap v-else>
    <v-flex xs12 v-if="!!loading">
      <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
    </v-flex>
    <empty v-if="!loading" :data="withData" :width="750">
      <zero-billing :mode-route="role == 'admin' ? 'admin-managerview-' : roleSetting.routePrefix" />
    </empty>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Empty from "@/containers/Empty.vue";
import ZeroBilling from "@/components/zero/Billing.vue";
import PlugHelpers from "@/library/helpers/PlugHelpers";
import mixin from "@/mixins";
import sort from "@/mixins/sort";
import { formatCost } from "@/library/helpers";

export default {
  name: "DeviceTable",
  components: {
    Empty,
    ZeroBilling,
  },
  mixins: [mixin, sort],
  props: {
    loadingCombinedPlugs: {
      type: Boolean,
      default: false,
    },
    hideAutoPayment: {
      type: Boolean,
      default: false,
    },
    additionalDataTable: {
      type: Boolean,
      default: false,
    },
    additionalTable: {
      type: Boolean,
      default: false,
    },
    hideSelectAll: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    onlyShowSelected: {
      type: Boolean,
      default: false,
    },
    allowSelection: {
      type: Boolean,
      default: false,
    },
    disabledDevices: {
      type: Boolean,
      default: false,
    },
    activeDevices: {
      type: Boolean,
      default: false,
    },
    dataplans: {
      type: Array,
      default: () => [],
    },
    hideSearchBar: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      enableRegexSearch: false,
      selectAll: false,
      filter: {
        status: {
          label: "All",
          property: "all",
        },
        statuses: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "Smart",
            property: 2,
          },
          {
            label: "Always On",
            property: 1,
          },
          {
            label: "Always Off",
            property: 0,
          },
        ],
        showHiddenDevice: false,
        hiddenOptions: [
          {
            label: "Yes",
            property: true,
          },
          {
            label: "No",
            property: false,
          },
        ],
      },
      error: false,
      headers: [
        { text: "", value: "", sortable: false, width: "20px" },
        { text: "Device ID", value: "identifier" },
        { text: "Description", value: "description" },
        { text: "Registration Date", value: "start" },
        { text: "Expiry Date", value: "expiry" },
        { text: "Auto Payment", value: "auto" },
        { text: "", value: "", sortable: false },
        { text: "USD/30 Days", value: "cost_usd" },
        { text: "CAD/30 Days", value: "cost_cad" },
      ],
      plugSort: {
        col: "identifier",
        asc: true,
      },
    };
  },
  computed: {
    ...mapState({
      darkTheme: (state) => ((state.Global.preferences || {}).settings || {}).darkTheme,
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Device", {
      hiddenDevices: "hiddenDevices",
    }),
    ...mapGetters(["plugs"]),
    selectedDevices: {
      get() {
        return this.selected;
      },
      set(v) {
        this.$emit("selected", v);
      },
    },
    combinedPlugs() {
      let plugs = this.plugs || [];

      let filteredPlugs = PlugHelpers.filter(plugs, {
        status: this.filter.status.property == "all" ? "all" : parseInt(this.filter.status.property),
        search: this.search,
        enableRegexSearch: this.enableRegexSearch,
      });

      if (this.disabledDevices) filteredPlugs = filteredPlugs.filter((p) => p.state === 0);
      if (this.activeDevices) filteredPlugs = filteredPlugs.filter((p) => p.state !== 0);

      filteredPlugs = filteredPlugs
        .map((p) => ({
          ...p,
          selected: this.selected.includes(p.identifier),
          dataplan: this.dataplans.find((plan) => plan.external_identifier === p.identifier),
        }))
        .filter((p) => !this.onlyShowSelected || p.selected);

      return filteredPlugs.sort(this.compareValues(this.plugSort.col, this.plugSort.asc ? "asc" : "desc"));
    },
    withData() {
      return this.combinedPlugs.length > 0;
    },
    tooltips() {
      return {
        search: `Allows search results to be filtered using regular expression (RegEx) patterns. Regex flavour is JavaScript/ECMAScript.<br/>
                Examples:
                <ul>
                  <li>(Jan)|(Aug) - Pattern match results with either "Jan" or "Aug".</li>
                  <li>(My).{1,}(Unit) - Pattern match results that have pattern starting with "My and ending with "Unit" (e.g. "MySpecialUnit")</li>
                </ul>`,
      };
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
    selectAll(v) {
      this.selectedDevices = v
        ? this.filter.showHiddenDevice
          ? this.combinedPlugs.map((p) => p.identifier)
          : this.combinedPlugs.filter((p) => !this.hiddenDevices.includes(p.identifier)).map((p) => p.identifier)
        : [];
    },
    filter: {
      handler: "updateSelectedDevices",
      deep: true,
    },
  },
  methods: {
    toggleCheckbox(identifier) {
      if (this.selectedDevices.includes(identifier)) {
        this.selectedDevices = this.selectedDevices.filter((id) => id !== identifier);
      } else {
        this.selectedDevices.push(identifier);
      }

      this.$nextTick(() => {
        this.updateSelectedDevices();
      });
    },
    updateSelectedDevices() {
      if (!this.filter.showHiddenDevice) this.selectedDevices = this.selectedDevices.filter((identifier) => !this.hiddenDevices.includes(identifier));

      if (this.selectedDevices.length === 0) {
        this.selectAll = false;
      }
    },
    sortCol(col) {
      this.plugSort.col = col;
      this.plugSort.asc = !this.plugSort.asc;

      console.log(this.plugSort);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  max-height: 100%;
  overflow-y: auto;
}
.disabled-plugs {
  background: rgba(50, 50, 50, 0.25) !important;
}
.selected-plugs {
  background: rgba(255, 253, 208, 0.3) !important;
}

.theme--light .v-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.theme--light .v-table tbody:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.theme--light .v-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.theme--light .v-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.theme--dark .v-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.theme--dark .v-table tbody:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.theme--dark .v-table {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.theme--dark .v-table {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.v-datatable thead th.column.sortable.active.desc .v-icon {
  -webkit-transform: none;
  transform: none;
}

.v-datatable thead th.column.sortable.active.desc .arrow-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
</style>
